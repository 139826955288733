@font-face {
	font-family: 'Century Gothic Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Century Gothic'),
		url('../fonts/gothic.woff') format('woff');
}

@font-face {
	font-family: 'Century Gothic Bold';
	font-style: normal;
	font-weight: bold;
	src: local('Century Gothic'),
		url('../fonts/gothic-bold.woff') format('woff');
}

@import "bootstrap.scss";



nav {

	background: $primary;
	//    font-size: 1.25rem;

	.navbar-brand {
		font-size: 1.6rem;
		text-transform: uppercase;
	}

	.dropdown-menu:last-of-type {
		//		background: green;
		margin-left: -60px;

	}

	.dropdown-menu {
		padding: 0;

		//		padding-left: 10px;



	}

	.dropdown-item {
		background: mix(white, $primary, 95%);

		padding-left: 10px;

		&:hover {
			background: mix(white, $primary, 90%);

		}

	}

	a {
		text-decoration: none;
	}
}


.durability {
	background: #939598;
	padding: 1rem 5rem;
	align-items: center;
	border: 10px white solid;
	//	border-top: 5px solid $primary;

	h5 {
		font-size: 1rem;
	}

	.col {
		background: white;
		margin: .5rem;
		border-top: 5px solid $primary;
		transition: 0.5s;

		&:hover {
			background: #d4d1d1;
			//			align-content: flex-end;
			border-top: 5px solid mix(white, $primary, 80%);
			transform: scale(1.01);
		}








	}

	.expand {

		padding: 1rem;
		vertical-align: middle;
		background: none;
		border: 0;

		h2,
		p {
			color: white;
		}



		&:hover {
			background: none;
			//			align-content: flex-end;
			border: none;
			transform: scale(1);
		}
	}
}

.precast {
	margin-left: 0;
	margin-right: 0;
}


.precast-desc {
	background: #496096 url(../images/precast_image.jpg);
	background-position: bottom;
	background-repeat: no-repeat;
	padding: 1rem 1rem 12rem 1rem;


	.lead {
		color: white;
		text-align: left;
		font-size: 1.1rem;
		line-height: 135%;
		background: none;
	}
}

.precast-intro {

	text-align: left;
	font-size: 1rem;
	padding: 1rem;
	color: $primary;

	p.intro {
		background: none;

	}

	li {
		margin-bottom: 1rem;
	}
}


.class-types {
	a {

		display: block;
		font-size: 1.2rem;
		padding: .75rem;
		background: $primary;
		outline: 1px #fff solid;
		outline-offset: -.25rem;





		&:link {


			text-decoration: none;
			color: white;

		}

		&:hover {
			background: mix(black, $yellow, 20%);
		}



	}
}





.modal-body a.download {
	padding: .5rem;
	background: $primary;
	color: white;
	text-decoration: none;
	font-size: .9rem;
	float: right;
	border-radius: .2rem;

	&:hover {
		background: mix(white, $primary, 20%);
	}
}

ul.nav li.dropdown:hover ul.dropdown-menu {
	display: block;
}

body {
	background: $gray-300;

}



main {
	counter-reset: my-sec-counter;
	background: white;
	//    min-height: calc(120vh);
	padding-top: 1.5rem;
	padding-bottom: 1rem;



	a:link {
		//		background: rgba($secondary, 0.3);
		padding: .2rem;
		font-weight: bold;
		color: mix(black, $yellow, 80%);

	}

	a:visited {
		color: mix(black, $yellow, 20%);
	}

	a:hover {
		color: mix(black, $yellow, 10%);

	}

	a:active {
		color: mix(white, $yellow, 10%);
	}
}


h1,
h2,
h3,
h4,
h5,
h6 {
	color: $primary;
}

h1 {
	font-weight: bold;
}

.opaque {
	opacity: 1;
}

.step {
	padding: 1.5rem 0;

}


.step:not(:last-child) {

	border-bottom: 1px dashed mix(white, $primary, 80%);

}


.further-guidance-wrap {



	h2 {
		background: $primary;
		padding: .6rem;
		//		padding-left: 1rem;
		color: white;
		position: absolute;
		top: -12%;
		left: -5%;
		font-size: 1.5rem;

		&:after {
			left: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-left-color: $primary;
			border-width: 25px;
			margin-top: -25px;

		}


	}

}

.further-guidance-box {
	background: $gray-300;
	border: 1px solid mix(white, $primary, 60%);
	padding: .5rem;

	h4 {
		font-size: 1.2rem;
		font-weight: bold;
		margin-bottom: -.1rem;

	}



}

.other-refs {
	background: mix(white, $primary, 80%);
	border: 1px mix(white, $primary, 40%) solid;
	padding: .5rem 1rem;

	h4 {
		font-size: 1rem;
		color: mix(white, $primary, 20%);
		margin-bottom: .5rem;
	}
}

.special-cases {


	.description {
		margin-top: 5rem;
	}

	.example {
		background: $gray-300;
		padding: 1rem;
		border: 5px solid white;
	}
}


.step-graphic {

	transition: transform 1.5s;
	text-align: center;

	a {
		//		display: contents;






		img.reg-graphic {
			max-width: 80%;



		}


		img.support-graphic {
			max-width: 75%;

		}

		img.concrete-graphic {
			max-width: 110%;
			margin-left: -40px;
			margin-top: -10px;


		}

	}

	&:hover {
		transform: scale(1.1);
	}
}

img.concrete-graphic-sm {
	//    background: red;
	max-width: 60%;
	margin-bottom: 3rem;
	margin-top: 3rem;
	//    text-align: center;
}

.diagram-box {
	background: mix(white, $primary, 95%);
	border: 1px solid mix(white, $primary, 80%);
	padding: 1rem;
	margin: 1rem 15%;
	text-align: center;

	a img {
		opacity: 0.75;

		&:hover {
			opacity: 1;

		}
	}

	p {
		background: mix(white, $primary, 80%);
		margin-top: 1rem;
		padding: .5rem;
		font-size: .8rem;
	}
}


.note {
	background: mix(white, $yellow, 80%);
	border: 1px solid mix(white, $yellow, 70%);
	padding: .5rem;
	color: mix(black, $yellow, 75%);
	margin: 1rem 0;

}

.home-hero {
	background: url("../images/bg-triangle.png") no-repeat;
	background-position: 107% 32%;
	background-size: 70%;


}

.step-name {

	display: flex;
	align-items: center;
	margin-top: 20px;


	h4 {
		background: $gray-100;
		padding: 1.5rem 2.5rem;
		border-left: 5px solid $primary;
		width: inherit;

		transition: all .5s;


		&::before {
			counter-increment: my-sec-counter;
			content: counter(my-sec-counter) ". ";
			font-size: 2rem;
			font-weight: bold;
			color: $primary;
			position: absolute;
			left: 0;

			padding-left: 30px;

		}



		&:hover {
			background: $gray-300;
			border-left: 10px solid $primary;

		}


	}


}




@mixin scope($scope-type) {
	background: mix(white, $scope-type, 90%);
	//    margin: 1rem;
	border-left: 3px solid $scope-type;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;




	h4 {
		text-transform: uppercase;
		color: mix(white, $scope-type, 20%);
		font-weight: bold;
		//            padding: 1rem;
		//            align-self: center;
		margin: 0;

	}

	p {

		padding: 1rem;
		margin: 0;
		color: mix(white, $scope-type, 10%);


	}


}

.primary-scope {

	@include scope($primary)
}

.designer-scope {

	@include scope($designer)
}

.builder-scope {

	@include scope($builder)
}

.producer-scope {

	@include scope($producer)
}




.number {
	border-left: 8px solid mix(white, $secondary, 60%);
	padding-left: .5rem;

}

.designer-box,
.builder-box,
.producer-box {



	//   margin-top: 5rem;
	font-size: 1.2rem;
	padding-right: 0;
	padding-left: 0;
	margin-top: 1.5rem;


	h3 {
		//    text-align: center;

		color: white;
		font-weight: bold;
		padding: 1rem;
		margin: 0;
		font-size: 1.3rem;



	}

	h4 {
		text-transform: uppercase;
		font-size: 1.2rem;
	}

	.main-standards {

		padding: .5rem;
		//       display:table-cell; 
		//        width:100%;
		//        margin-bottom: 0;




		ul {
			list-style-type: none;
			font-size: 1.4rem;
			//        text-align: right;
		}

	}


	.box-content {

		padding: .5rem;
		//        padding-left: 2rem;
		//        min-height: 500px;
		font-size: 1rem;
	}



}


.logos img {
	filter: grayscale(100%);
	transition: filter 0.15s;



	a {
		cursor: pointer;

		&:hover {
			cursor: pointer;
		}
	}

	&:hover {
		filter: grayscale(0%);

	}
}


p.asterisk-note {
	font-weight: bold;
	color: $gray-700;
	font-size: .9rem;
	text-align: right;
	line-height: 1rem;
	margin-bottom: 3rem;

}

@mixin box($box-color, $bg-image) {

	background: mix(white, $box-color, 90%);
	border: 15px solid white;
	
	.box-head {
		background: $box-color;
		display: flex;
		align-content: flex-start;
		
		

	h3 {


		width: 75%;
		/*url("../images/#{$bg-image}.png") no-repeat 99%*/
		
		background-origin: padding-box;
		background-size: contain;






	}
		
	}

	h4 {
		color: $box-color;
	}

	.main-standards {
		border-top: 2px solid mix(white, $box-color, 60%);
		background: mix(white, $box-color, 95%);

	}

	.box-content {

		color: mix(black, $box-color, 20%);
	}

	a {
		color: mix(black, $box-color, 50%);
		font-weight: bold;
		text-decoration: underline;
		//		font-size: 80%;

	}


}

.designer-box {
	@include box($designer, designer);

	.character {
//		position: absolute;
//		top: -1.4%;
//		right: 2%;
//		width: 28%;
		
		width: 25%;

	}
}

.builder-box {

	@include box($builder, builder);

	.character {
//		position: absolute;
//		top: -1.5%;
//		right: 2%;
//		width: 30%;
		width: 25%;
//
	}
}



.producer-box {

	@include box($producer, producer);

	.character {
//		position: absolute;
//		top: -2.2%;
//		right: 2%;
//		width: 36%;
	width: 25%;
//
	}
}

.na {
	opacity: 0.5;


	p {
		text-align: center;
		font-size: 1.4rem;
		font-weight: bold;
		//		transform: rotate(18deg);
		padding-top: 2rem;

	}
}


table.properties-table {
	border: white;
	font-size: 1.1rem;
	width: 60%;
	margin: 0 auto;

	th[scope=row] {
		font-weight: bold;

		color: $primary;
		text-align: right;
	}
}

.modal-table {


	th {
		background: $primary;
		color: white;
		font-size: 1rem;
	}

	td {
		font-size: .9rem;
	}


}

.modal-header {
	background: mix(white, $primary, 80%);
}

@mixin table-cell($box-color, $mix-amount, $text-mix-amount) {
	background: mix(white, $box-color, $mix-amount);
	color: mix(white, $box-color, $text-mix-amount);
	text-align: center;
	font-size: 80%;
	border-bottom: 2px solid white;

	strong {
		color: mix(black, $box-color, 80%);
//		font-size: 1.05rem;
	}


}


th.designer-cell {
	@include table-cell($designer, 0%, 100%);
	font-size: 1.2rem;
}

th.builder-cell {
	@include table-cell($builder, 0%, 100%);
	font-size: 1.2rem;
}

th.producer-cell {
	@include table-cell($producer, 0%, 100%);
	font-size: 1.2rem;
}

td.designer-cell {
	@include table-cell($designer, 70%, 0%);

	//	&:hover {
	//		@include table-cell($designer, 50%, 100%);
	//	}
}

td.builder-cell {
	@include table-cell($builder, 70%, 0%);

	//	&:hover {
	//		@include table-cell($builder, 50%, 100%);
	//	}
}

td.producer-cell {
	@include table-cell($producer, 70%, 0%);

	//	&:hover {
	//		@include table-cell($producer, 50%, 100%);
	//	}
}





ol.breadcrumb {
	font-size: .8rem;
}

nav[aria-label=breadcrumb] {
	background: $gray-200;
}

.tip {
	color: purple;
	background: mix(white, purple, 85%);
	//    padding: .5rem;
	margin: 0 .25rem;
	border-radius: 1rem;
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	text-align: center;
	font-size: .9rem;

	&:hover {
		background: mix(white, purple, 75%);


	}


}

.main-image {
	//    background: $gray-100;
	//    border: 1px solid $gray-200;
	//	padding-top: 1rem;
	border-top: 5px solid $primary;
	background: $gray-200;


	.text-center {

		padding: 0;
	}

	img {
		//		max-width: 400px;
	}

	img.no-width {
		max-width: none;
	}

	p {
		//		background: $gray-300;
		//		padding: .5rem;
		//		margin: 0;
		//		margin-top: 1rem;
		background: white;
	}
}


.mix-design-header {

	background: url(../images/3-7-mix-design.jpg) no-repeat;
	background-size: cover;



	.required-properties {
		margin-top: 1rem;






		h2 {
			font-size: 1.2rem;
			font-style: italic;
			font-weight: bold;
			color: white;
		}
	}



	.mix-design-btn {
		font-size: 1.4rem;
		color: white;
		padding: .8rem;

		text-align: left;
		margin: 0;
		margin-left: 1.5rem;
		margin-right: 1.5rem;


		a {
			color: white;
			text-decoration: none;
			background: $primary;
			display: block;
			padding: .5rem;
			transition: transform .5s;

			&:hover {
				background: mix(white, $primary, 10%);
				transform: translateX(5px);
			}
		}

	}

	.mix-specs-btn {
		font-size: 1.2rem;
		color: white;
		padding: .8rem;
		font-weight: 100;

		text-align: center;
		margin: 0;



		a {
			color: white;
			text-decoration: none;
			background: $primary;
			display: block;
			padding: .5rem;
			transition: transform .5s;

			&:hover {
				background: mix(white, $primary, 10%);
				transform: translateX(5px);
			}
		}

	}


	.other-considerations {


		background: rgba(white, 0.2);
		width: 90%;
		text-align: center;
		padding: 1.2rem;
		margin: 20% 0;


		h3 {
			font-size: .9rem;

		}

		.mix-specs-btn {
			padding: .2rem;
			text-align: left;




		}

		.mix-first a {
			font-size: 1.5rem;
			background: url(../images/pdf-icon.png) no-repeat 95% 50% mix(black, $primary, 60%);

		}

		.mix-second a {
			background: url(../images/pdf-icon.png) no-repeat 95% 50% mix(white, $primary, 30%);

		}




		.considerations {
			font-size: 1.2rem;
			color: $primary;

			//	background: rgba(white, 0.5);

			margin-bottom: 1.2rem;
			margin-right: 1.5rem;
			margin-left: 1.5rem;

			h2 {
				font-size: 1.4rem;
				font-style: italic;
				font-weight: bold;
				margin-bottom: 2.5rem;
				color: $primary;
			}


		}

	}

	.col-6,
	.col-12 {
		padding: 0;
		margin: 0;
	}
}

.parts {

	h3 {
		font-weight: bold;
		text-transform: uppercase;
		color: $primary;
		margin-top: 1rem;
	}

	.desc {
		background: rgba($primary, 0.05);
		border: 1px solid white;
		padding: .75rem;
		color: mix(black, $primary, 3%);
		margin-bottom: 1rem;

		h4 {
			//			float: left;
			border-bottom: 1px solid mix(white, $primary, 60%);

			em {
				font-size: 1.2rem;
			}

		}

	}



}

.assumptions li {
	list-style-image: url('../images/blue-bullet.png');

}


.design-type {

	border-bottom: 1px solid $gray-300;
	padding: .5rem;
	padding-left: 4rem;

	p {
		margin: 0;
		//        padding: 0;
	}

}

.building {
	background: url('../images/building-icon.png') no-repeat;
	background-position: 2% 30%;
}

.bridge {
	background: url('../images/bridge-icon.png') no-repeat;
	background-position: 2% 30%;
}

.monument {
	background: url('../images/monument-icon.png') no-repeat;
	background-position: 3% 30%;
}

td {
	font-size: 1.2rem;

}

footer {
	// height: 50px;
	//	  margin-top: 1rem;	
	//    background: $gray-300;
	padding: 1rem 0;
}

$ie-color: #00b38c;

.ie-flag {
	margin-left: -42px;
	margin-top: 2px;
}


.flags {
	display: flex;
	margin: 0 auto;
	z-index: 700;
}

.eu-col,
.ie-col {
	h2 {
		font-weight: bold;
		text-transform: uppercase;
	}

}


.eu-col {



	.eu-box {
		background: $gray-300;
	}

	.top-line:after {
		z-index: 600;
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		position: absolute;
		pointer-events: none;
		width: 20px;
		height: 20px;
		background: $gray-400;
	}

	.highlight {
		background: $primary;






		h4 {
			color: white;
		}

		&:hover {
			background: mix(white, $primary, 50%);
		}

		a {
			&:link {
				color: white;
			}

			&:visited {
				color: white;
			}
		}




	}

	.highlight-arrow {

		&:before {
			left: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-left-color: $primary;
			border-width: 20px;
			margin-top: -15px;
			z-index: 600;
		}
	}

	.first-line:before {

		z-index: 600;
		bottom: 100%;
		left: 90%;
		border: solid transparent;
		content: " ";
		position: absolute;
		pointer-events: none;
		width: 20px;
		height: 100px;
		background: $gray-400;

	}



}

.eu-reg-head-box,
.ie-reg-head-box {
	text-align: center;
	color: white;
	font-size: 1.8rem;
	padding: .5rem;
	margin-bottom: .5rem;
}

.eu-reg-head-box {
	background: $primary;
	display: inline-block;
	margin-left: -25px;
	font-size: 1.5rem;
	padding-left: 20px;
	
	
}




h2.ie-reg-head-box {

	background: $ie-color;

	&:before {
		height: 800px;
		width: 10px;
		content: "";
		background: mix(white, $ie-color, 50%);
		position: absolute;
		top: 95%;
		left: 60%;


	}

	&:after {
		top: 900px;
		left: 51%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-top-color: mix(white, $ie-color, 50%);
		border-width: 30px;




	}
	
	a {
		color: white;
	}

}



.ie-col {

	.ie-box {
		background: $ie-color;


		h3 {
			color: mix(black, $ie-color, 50%);
		}


	}

	.top-line:after {
		z-index: 600;
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		position: absolute;
		pointer-events: none;
		width: 20px;
		height: 20px;
		background: #7c9d93;
	}

	.long-line {
		transform: translateX(20%);

		&:after {

			//		left: 60%;
			height: 179px;




		}
	}


	.first-line:before {

		z-index: 600;
		bottom: 100%;
		left: 10%;
		border: solid transparent;
		content: " ";
		position: absolute;
		pointer-events: none;
		width: 20px;
		height: 100px;
		background: #7c9d93;

	}

	.highlight {
		background: #2d7b63;

		a {
			color: white;
		}




		h3 {
			color: white;
		}

		&:hover {
			background: mix(white, #3faf8d, 50%);
		}
	}





}

.eu-box,
.ie-box {

	padding: 1rem;
	border: 10px solid white;

	a {
		text-decoration: underline;
	}
}


.tech-guidance-docs {
	padding-top: 1.5rem;
	color: $primary;

	.row {
		//		margin-bottom: 2rem;
	}

	h2 {
		font-size: 1.8rem;
		margin-bottom: 2rem;

		strong {
			margin-right: 1.5rem;

		}
	}





}




//.btm-arrow {
////	position: relative;
////	background: #d1d3d4;
////    z-index: 600;
//}


.eu-flag {
	img {
	
	height: 100px;
	
}
}

.flags  {
	img {
	
	height: auto;
	
}
}

	

	
	

.regs {

	h4 {
		text-align: right;
		margin-top: 1rem;



	}


	h3 {


		//		
		//		
		//		&:before {
		//			background: $gray-200;
		//			width: 2px;
		//			height: 23px;
		//			position: absolute;
		//			content: " ";
		//			bottom: 100%;
		//			left: 50%;
		//			z-index: -100;
		//			
		//		}
	}

	.regnum-2,
	.regnum-3,
	.regnum-4,
	.regnum-5,
	.regnum-6,
	.regnum-7,
		{
		margin-top: 1rem;
	}

	.regnum-1 {

		h4 {
			&:before {
				content: url(../images/resistance-icon.png);
				padding-right: 5px;
			}
		}
	}

	.regnum-2 {

		h4 {
			&:before {
				content: url(../images/fire-icon.png);
				padding-right: 5px;
			}
		}
	}

	.regnum-7 {

		h4 {
			&:before {
				content: url(../images/natural-icon.png);
				padding-right: 5px;
				vertical-align: middle;
			}
		}
	}


	.reg-number {

		height: 50px;
		width: 50px;
		border-radius: 60%;
		background: $gray-400;
		padding: 10px 15px;
		color: white;
		position: relative;
		font-weight: bold;
		text-align: center;
		z-index: 200;

	}

	.reg-highlight {
		background: $primary;
		cursor: grab;
		transition: all .5s;
		transition-property: background, transform;

		&:hover {
			background: mix($white, $primary, 30%);
			transform: scale(1.05);

			&:after {
				background: mix(black, $yellow, 20%);

			}
		}

		&:after {
			content: "+";
			background: $yellow;
			width: 20px;
			height: 20px;
			position: absolute;
			right: -5px;
			top: 0;
			border-radius: 10px;
			padding: 5px;
			border: 1px white solid;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: .9rem;


		}
	}






	.reg-desc {
		background: $gray-300;
		padding: 1.5rem 1rem;
		border: none;
		margin: 0;
		display: none;
		
		h4 {
			display: none;
			text-align: left;
		}


		&:before {

			position: absolute;
			z-index: -600;
			right: 95%;
			top: 3%;
			border: solid transparent;
			content: " ";
			position: absolute;
			pointer-events: none;
			width: 60px;
			height: 25px;
			background: $gray-300;


		}
	}
}

.standards-wrap {}

.basis-standards,
.basis-standards-actions,
.designer-standards,
.builder-standards,
.producer-standards {
	position: absolute;
}

.builder-standards {
	opacity: .7;
	transition: transform .5s, opacity .5s;

	&:hover {
		opacity: 1;
		transform: translateY(-10px)
	}
}

.producer-standards {

	left: 391px;
	top: -3px;
	opacity: .7;
	transition: transform .5s, opacity .5s;

	&:hover {
		opacity: 1;
		transform: translateY(-10px)
	}
}

.designer-standards {
	top: 365px;
	left: 25px;
	opacity: .7;
	transition: transform .5s, opacity .5s;

	&:hover {
		opacity: 1;
		transform: translateY(-10px)
	}

}

.basis-standards {
	top: 277px;
	left: 172px;
	z-index: 100;
	opacity: .7;
	transition: transform .5s, opacity .5s;

	&:hover {
		opacity: 1;
		transform: translateY(-5px)
	}
}
	
	
.basis-standards-actions {
	top: 350px;
	left: 172px;
	z-index: 100;
	opacity: .7;
	transition: transform .5s, opacity .5s;

	&:hover {
		opacity: 1;
		transform: translateY(-5px)
	}
	}



.safety-col {
	background: rgba(#dfbc27, 0.1);
	border: 10px white solid;
	text-align: center;
	padding: 1.5rem;

}


img.safety-first {
	position: relative;
	top: 0%;
	z-index: 600;
}

.safety-reg-1,
.safety-reg-2,
.safety-reg-3,
.safety-reg-4 {

	margin: 2.5rem 0;
}

.safety-reg-1 {

	&:before {
		content: url(../images/quarry-cover.png);
		position: absolute;
		left: -25%;
		top: 25%;
	}
}

.safety-reg-2 {

	&:before {
		content: url(../images/gen-app-cover.png);
		position: absolute;
		left: -25%;
		top: 50%;

	}
}

.safety-reg-3 {

	&:before {
		content: url(../images/act-2005.png);
		position: absolute;
		left: -20%;
		top: 75%;

	}


}

.safety-reg-4 {


	margin-top: 9rem;

	a {
		color: #dfbc27;
		text-decoration: none;
		font-style: italic;
		font-size: 1.6rem;
		display: block;
	}

}

.safety-second {
	&:before {
		content: url(../images/cdcs-cogs.png);
		position: absolute;
		right: 60%;
		top: 0%;
	}

	.designer,
	.builder {
		margin: 5rem 0;
	}
}



@include media-breakpoint-down(md) {

	.home-hero {
		background: none;
	}

	.step {
		border: 10px solid $gray-300;
		padding: .5rem;


	}



	.further-guidance-wrap {
		margin-top: 2rem;


		h2 {

			top: -5%;
			left: 0;


		}

	}

	.further-guidance-box {
		padding-top: 3.5rem;

	}

	.designer-box,
	.builder-box,
	.producer-box {
		height: 100%;



		.box-content {
			//              min-height: 0;

		}


		.character {
//			position: absolute;
//			top: 0%;
//			right: 10px;
//			width: 18%;
			width: 25%

		}
	}

	table.properties-table {
		font-size: 1.15rem;
		width: 100%;
	}

	.regs {

		h4 {
			text-align: left;


		}

		.reg-desc:before {
			bottom: 100%;
			left: 3%;
		}



	}

	.safety-reg-1,
	.safety-reg-2,
	.safety-reg-3,
	.safety-reg-4,
	.safety-second {

		&:before {
			content: " ";

		}
	}


}

.parts {

	a img {
		transition: transform .5s;

		&:hover {
			transform: scale(1.05);
		}

	}

	td {
		vertical-align: middle;
	}

	tr {
		margin-bottom: 100px;

		&:hover {
			background: mix(white, $primary, 92%);

		}
	}
}


.en-1992-part {

	background: mix(white, $primary, 90%);
	padding: .5rem;
	margin-bottom: .5rem;
	color: $primary;


	h3 {
		//		font-weight: bold;
		text-transform: uppercase;
		border-bottom: 1px solid mix(white, $primary, 60%);

		margin-top: 1rem;
		white-space: nowrap;
		font-size: 1.6rem;
		//		font-weight: bold;

		&::before {
			content: url(../images/blue-bullet.png);
			margin-right: .5rem;
		}
	}



	h4 {
		//			float: left;
		//			border-bottom: 1px solid mix(white, $primary, 60%);
		//			font-weight: bold;
		font-size: 1.3em;


	}


}

.en1992-modal-list li {
	margin-bottom: .6rem;
	font-size: 1.1rem;
	color: $primary;
}


.table-mix-specs {

	color: $primary;



	thead {
		background: $primary;
		color: white;

		th {
			font-size: 1rem;
			text-align: center;
			font-weight: bold;
			padding: .5rem;
			font-weight: bold;

			a.header-btn {
				display: inline-block;
				font-size: .8rem;
				text-decoration: none;
				background: mix(black, $yellow, 20%);
				padding: .25rem .75rem;
				border: 2px solid white;
				border-radius: 2rem;
				font-weight: bold;
				color: white;

				&:hover {
					background: mix(black, $yellow, 10%);

				}
			}
		}

		th:first-child {
			text-align: left;
		}
	}

	tbody {
		background: mix(white, $primary, 90%);

		td {
			font-size: .9rem;
			padding: .5rem;
			text-align: center;
			border: 2px solid white;
			vertical-align: middle;


		}


		td:first-child {
			text-align: left;
		}



	}
}

.forma-row-border {
	border-top: 4px solid white;
}

.btn-circle {
	border-radius: 8rem;
	//	padding: .25rem;
	margin: .25rem;
	font-weight: bold;
	font-size: .75rem;
}

.correct-show {
	background: #CBE0B8;

	&:after {
		width: 0;
		height: 0;

		//  position: absolute;

		color: darken(#CBE0B8, 40%);
		font-size: 1.25rem;
		content: "✔";
		top: 30px;
		left: -50px
	}
}

.incorrect-show {
	background: #EDC9AE;

	&:after {
		width: 0;
		height: 0;

		//  position: absolute;

		color: darken(#EDC9AE, 40%);
		font-size: 1.25rem;
		content: "✘";
		top: 30px;
		left: -50px
	}
}

.sq-root {
	font-weight: bold;
	text-align: center;
	font-size: 1.5rem !important;

}

.head-note {
	font-size: .8rem;

}

a[href="#mix-notes"] {

	&:link {
		color: mix(black, $yellow, 20%);
	}
}

@include media-breakpoint-down(lg) {

	.dropdown-item {
		margin-left: 50px;
	}

}



@include media-breakpoint-down(md) {



	.diagram-box {

		margin: 0;
		padding: .25rem;
	}
}

.step-3 ~ .step-graphic {

	margin-top: 10px;
}

.precast-table {
	width: 80%;
	margin: 0 auto;

	thead {
		th {
			text-align: left;
			font-size: .85rem;
		}
	}

	tbody {
		td:first-child {
			background: $gray-100;
			width: 15%;
		}

		td {
			font-size: .8rem;
		}

	}




}



@include media-breakpoint-down(sm) {

	.precast-table {
		width: 100%;

	}

	h1 {
		font-size: 2rem;
	}



	nav {
		.dropdown-menu:last-of-type {
			//		background: red;
			margin-left: -10px;

		}
	}

	.step-3 ~ .step-graphic {
		margin-left: 40px;

	}

	img.concrete-graphic-sm {
		//    background: red;
		max-width: 100%;

	}

	nav strong {
		font-size: 1.2rem;
	}



	.dropdown-item {
		margin-left: 0 !important;

	}
	
	.regs .reg-desc {
	margin-top: -20px;
		display: block;
		
	}

	
	.regs .reg-highlight {
		
		&:after {
			display: none;
		}
	}

.first-line:before {

		
		display: none;

	}
	
	.eu-col .highlight-arrow:before {
   display: none;
	}
	
	.eu-box:after, .ie-box:after  {
		display: none;
	}
	
	
	.ie-col {
		h2.text-right {
			text-align: left !important;
		}
	}
	
	.ie-col .long-line {
	 transform: translateX(0%);
	}
	
	.reg-desc {
		h4 {
			display:block !important;
			text-align: left !important;
			font-size: 1.2rem;
			font-weight: bold;
			margin-top: 0;
			
			&:before {
				display: none;
			}
		}
	}
	
	.regs .row .col-12 h4 {
		display: none;
	}
	
	h2.ie-reg-head-box {

	background: $ie-color;

	&:before {
	display: none;


	}

	&:after {
	display: none;



	}
		
		a {
			color: white;
		}

}
	
	.tech-guidance-docs h2  {
		font-size: 1rem;
	}
	
	
	.justify-content-end .eu-flag {
		display: none;
	}
	
	.align-items-center
	{
		h4 {
		font-size: .8rem;
	}
		
		.ie-reg-head-box {
			font-size: 1rem;
			
		}
	}
	.offset-md-2 {
		h5 {
			text-align: left;
		}
	}


}

@include media-breakpoint-down(xs) {

	h1 {
		font-size: 1.5rem;
	}

	.number {
		font-size: 1rem;
	}

	.primary-scope,
	.designer-scope,
	.producer-scope {

		margin: 1rem;
	}





}